import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

export default function Footer(props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            footerLinks {
              name
              link
            }
          }
        }
      }
    `
  )

  return (
    <footer className="bg-custom-gray py-8">
      {/* <div className="text-center text-white mb-10">
        {site.siteMetadata.footerLinks.map(({ name, link }, index) => (
          <Link
            key={index}
            to={link}
            className={[
              "inline-block",
              index !== site.siteMetadata.footerLinks.length - 1 ? "mr-6" : "",
            ]
              .filter(Boolean)
              .join(" ")}
          >
            {name}
          </Link>
        ))}
      </div> */}
      <div className="flex flex-col md:flex-row items-center justify-center text-white">
        <span>
          &copy; {new Date().getFullYear()} {site.siteMetadata.title}
        </span>
        <span className="hidden md:inline-block">&nbsp;&mdash;&nbsp;</span>
        <span>Albany, Wisconsin</span>
      </div>
    </footer>
  )
}
