import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import Container from "./structure/container"

// import { getCartItemCount } from "../pages/cart"

export class Header extends React.Component {
  constructor(props) {
    super(props)
    this.headerRef = React.createRef()

    this.state = {
      isOpen: false,
      cartItemsCount: 0,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
    this.handleScroll()
    // window.addEventListener("storage", this.handleStorage)
    // this.handleStorage()
    let prevValue = this.headerRef.current.clientHeight
    const handle = setInterval(() => {
      let nextValue = this.headerRef.current.clientHeight
      if (nextValue === prevValue) {
        clearInterval(handle)
        this.props.onHeightChange(nextValue)
      } else {
        prevValue = nextValue
      }
    }, 50)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
    window.removeEventListener("storage", this.handleStorage)
  }

  handleScroll = () => {
    if (document.body.classList.contains("page-index")) {
      const header = this.headerRef.current

      if (header) {
        if (window.scrollY > 70) {
          header.classList.add("off-top")
        } else {
          header.classList.remove("off-top")
        }

        if (window.scrollY > window.innerHeight - header.clientHeight) {
          header.classList.add("over-main")
        } else {
          header.classList.remove("over-main")
        }
      }
    }
  }

  // handleStorage = () => {
  //   this.setState({ cartItemsCount: getCartItemCount() })
  // }

  handleClick = () => {
    this.setState(state => ({ isOpen: !state.isOpen }))
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
                navLinks {
                  name
                  link
                }
              }
            }
          }
        `}
      >
        {data => (
          <header
            ref={this.headerRef}
            className="transition-colors duration-150 text-white fixed left-0 right-0 z-50"
          >
            <nav
              className="py-8 md:py-12"
              style={{
                transition: `padding-top, padding-bottom`,
                transitionDuration: `150ms`,
              }}
            >
              <Container className="flex items-center justify-center flex-wrap">
                <div className="brand relative">
                  <Link
                    to="/"
                    className="text-3xl md:text-5xl"
                    style={{ letterSpacing: `0.5px` }}
                  >
                    {data.site.siteMetadata.title}
                  </Link>
                </div>
                {/* <div className="flex md:hidden">
                  <Link
                    to="/cart/"
                    className="cart relative mr-5"
                    style={{ width: `30px`, height: `30px` }}
                  >
                    <span
                      className="text-base rounded-full bg-red-500 leading-none"
                      style={{ padding: `2px 5px` }}
                    >
                      {this.state.cartItemsCount}
                    </span>
                  </Link>
                  <button
                    onClick={this.handleClick}
                    className="flex items-center focus:outline-none"
                  >
                    <svg
                      className="fill-current h-8 w-8"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Menu</title>
                      {this.state.isOpen ? (
                        <path
                          fillRule="evenodd"
                          d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                        />
                      ) : (
                        <path
                          fillRule="evenodd"
                          d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                        />
                      )}
                    </svg>
                  </button>
                </div>
                <div
                  className={`link-wrapper ${
                    this.state.isOpen && window.innerWidth < 768
                      ? "block bg-custom-beige text-black"
                      : "hidden"
                  } w-full mt-8 text-center border border-solid border-black border-2 md:mt-0 md:border-none md:bg-transparent md:text-white md:flex md:items-center md:w-auto`}
                >
                  <div className="block md:flex align-center text-xl mb-6 md:mb-0 lg:flex-grow">
                    {data.site.siteMetadata.navLinks.map(
                      ({ name, link }, index) => (
                        <Link
                          key={index}
                          to={link}
                          className="block mt-6 md:inline-block md:mt-0 md:mr-5"
                        >
                          {name}
                        </Link>
                      )
                    )}
                    <Link
                      to="/cart/"
                      className="cart hidden md:block mt-6 md:inline-block md:mt-0 md:mr-5"
                      style={{ width: `30px`, height: `30px` }}
                    >
                      <span
                        className="text-base rounded-full bg-red-500 leading-none"
                        style={{ padding: `2px 5px` }}
                      >
                        {this.state.cartItemsCount}
                      </span>
                    </Link>
                  </div>
                </div> */}
              </Container>
            </nav>
          </header>
        )}
      </StaticQuery>
    )
  }
}
