import React from "react"

import { Header } from "../components/header"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import FontFaces from "./fontFaces"

export default class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      headerHeight: 0,
    }

    this.handleScroll = this.handleScroll.bind(this)
    this.handleHeaderHeightChange = this.handleHeaderHeightChange.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
    this.handleScroll()
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll() {
    if (document.body.classList.contains("page-index")) {
      if (window.scrollY > window.innerHeight) {
        document.body.classList.add("past-fold")
      } else {
        document.body.classList.remove("past-fold")
      }
    }
  }

  handleHeaderHeightChange(height) {
    this.setState({ headerHeight: height })
  }

  render() {
    return (
      <div className="site flex flex-col">
        <Helmet
          bodyAttributes={{
            class: `page-${this.props.slug}`,
          }}
        >
          <link rel="preconnect" href="https://www.gstatic.com"></link>
          <link rel="preconnect" href="https://fonts.gstatic.com"></link>
          <script
            rel="preload"
            src="https://www.google.com/recaptcha/api.js?render=6LfXk7YZAAAAAACvkHUOyCRLF3B0J9mIOMyEJLLd"
          ></script>
        </Helmet>
        <FontFaces />
        <Header onHeightChange={this.handleHeaderHeightChange} />
        <main
          className="bg-custom-beige flex-grow"
          style={{
            paddingTop: `${this.state.headerHeight}px`,
          }}
        >
          {this.props.children}
        </main>
        <Footer />
      </div>
    )
  }
}
