import React from "react"

export default function Heading(props) {
  const level = parseInt(props.level)
  const HeadingLevel = `h${level || 1}`

  const sizeClasses = (function (level) {
    switch (level) {
      case 1:
        return "text-4xl mb-6"

      case 2:
        return "text-3xl mb-5"

      case 3:
        return "text-2xl mb-4"

      case 4:
        return "text-xl mb-3"

      case 5:
        return "text-lg mb-2"

      case 6:
        return "text-md mb-1"

      default:
        return ""
    }
  })(level || 1)

  return (
    <HeadingLevel
      className={`${sizeClasses} ${props.className ? props.className : ""}`}
    >
      {props.text ? props.text : props.children}
    </HeadingLevel>
  )
}
